import type {
  SimpleTableColumn,
  SimpleTableFooterSlot,
  SimpleTableHeaderSlot,
  SimpleTableItemSlot,
} from './types'

export const getTemplate = (column: SimpleTableColumn, result: any) => {
  if ('cell' in column && typeof column.cell === 'function') {
    return column.cell(result[column.id], { result })
  }
  return result[column.id]
}

export const createHeaderSlotProps = (
  column: SimpleTableColumn,
  index: number,
): SimpleTableHeaderSlot => {
  return {
    id: column.id,
    value: column.header,
    index,
  }
}

export const createFooterSlotProps = (
  column: SimpleTableColumn,
  index: number,
): SimpleTableFooterSlot => {
  return {
    id: column.id,
    value: column.footer,
    index,
  }
}

export const createItemSlotProps = (
  column: SimpleTableColumn,
  item: any,
  index: number,
): SimpleTableItemSlot => {
  return {
    id: column.id,
    item,
    value: getTemplate(column, item),
    index,
  }
}
